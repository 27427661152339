

* {
  height: min-content;
  margin: 0;
  padding: 0;
  cursor: none;
  /* font-weight: normal; */
  /* outline: .5px rgb(255, 0, 0) solid; */
  /* box-sizing: ; */
  overscroll-behavior: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
  
}

/* titles */
h1 {
  font-family: 'MatissePro';
  text-transform: uppercase;
  letter-spacing: .2rem;
  font-weight: bold;
  -webkit-transform: scale(1, 1.8);
  -moz-transform:scale(1, 1.8);
  -o-transform: scale(1, 1.8);
  transform: scale(1, 1.8);
}

/* nav btns */
h2,h6 {
  font-family: 'DesignerGenes', 'Courier New Bold', Courier, monospace;
  letter-spacing: .1rem;
    font-weight: normal;
  -webkit-transform: scale(1.2, 1.4);
  -moz-transform: scale(1.2, 1.4);
  -o-transform: scale(1.2, 1.4);
  transform: scale(1.2, 1.4);
}


/* accent btn */
h3{
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  letter-spacing: .1rem;
  font-family: 'Helvetica';
  -webkit-transform: scale(1, 1.3);
  -moz-transform: scale(1, 1.3);
  -o-transform: scale(1, 1.3);
  transform: scale(1, 1.3);
}

/* landing subtitle */
h4 {
  font-family: 'Helvetica';
  /* -webkit-transform: scale(1.5, 1.2);
  -moz-transform: scale(1.5, 1.2);
  -o-transform:scale(1.5, 1.2);
  transform: scale(1.5, 1.2); */
}
/* regular text */


p {
  font-family: 'Helvetica';
  font-weight: bolder;
  color: white;
  font-size: 1rem;
  line-height: 1.5rem;
  word-spacing: .05rem;
  letter-spacing: .05rem;


}

@media screen and (max-width: 576px) {
  p{
    font-size: .875rem;
    line-height: 1.5rem;
    /* letter-spacing: 1px; */
  /* font-weight: normal */
  }
}



@font-face {
  font-family: "MatissePro";
  src: url(".././public/fonts/MatissePro.otf") format("otf");
}

@font-face {
  font-family: "DesignerGenes";
  src: url(".././public/fonts/DesignerGenes.ttf") format("truetype");
}




a {
  all: unset;
  text-decoration: none;
  border: none;
}



/* LOADER */
@keyframes loader_5191 {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


#sq1 {
  margin-top: -25px;
  margin-left: -25px;
  animation: loader_5191 675ms ease-in-out 0s infinite alternate;
}

#sq2 {
  margin-top: -25px;
  animation: loader_5191 675ms ease-in-out 75ms infinite alternate;
}

#sq3 {
  margin-top: -25px;
  margin-left: 15px;
  animation: loader_5191 675ms ease-in-out 150ms infinite;
}

#sq4 {
  margin-left: -25px;
  animation: loader_5191 675ms ease-in-out 225ms infinite;
}

#sq5 {
  animation: loader_5191 675ms ease-in-out 300ms infinite;
}

#sq6 {
  margin-left: 15px;
  animation: loader_5191 675ms ease-in-out 375ms infinite;
}

#sq7 {
  margin-top: 15px;
  margin-left: -25px;
  animation: loader_5191 675ms ease-in-out 450ms infinite;
}

#sq8 {
  margin-top: 15px;
  animation: loader_5191 675ms ease-in-out 525ms infinite;
}

#sq9 {
  margin-top: 15px;
  margin-left: 15px;
  animation: loader_5191 675ms ease-in-out 600ms infinite;
}